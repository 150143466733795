<template>
  <div class="grid gap-4 grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 transition-all duration-500">
    <template v-for="(item, index) in displayedVehicles" :key="index">
      <FrontpageVehicle
        class="col-span-full md:col-span-1"
        :item="item"
        :loading="lazyLoading"
        :hide-condition
      />
    </template>

    <NuxtLink
      v-if="linkMore"
      :to="linkMoreRoute"
      class="flex items-center group hover:text-primary duration-150 mx-auto col-span-full mt-4 lg:mt-8"
    >
      <span class="mr-2 font-medium"> {{ moreText }}</span>
      <svg
        class="size-9 fill-gray-600 group-hover:text-primary group-hover:translate-x-4 duration-500"
        viewBox="0 -960 960 960"
      >
        <path d="m560-240-56-58 142-142H160v-80h486L504-662l56-58 240 240-240 240Z" />
      </svg>
      <div class="h-5 w-1 -ml-0.5 bg-primary group-hover:-translate-x-6 duration-500"></div>
    </NuxtLink>

    <FrontpageLoadMoreButton
      v-if="loadMoreButton"
      class="col-span-full mt-4 lg:mt-8"
      :disabled="loading"
      :text="moreText"
      @click="loadMoreItems"
    />

    <NuxtLink
      v-else-if="loadMore"
      :to="topDealsPage ? '#finder' : '/firmenwagen-finder'"
      class="flex items-center group hover:text-primary duration-150 mx-auto col-span-full mt-4 lg:mt-8"
    >
      <span class="flex flex-col md:flex-row gap-1 mr-2 font-medium">
        Zum
        <p class="text-primary">Firmenwagen-Finder</p>
        für weitere Angebote
      </span>
      <svg
        class="size-9 fill-gray-600 group-hover:text-primary group-hover:translate-x-4 duration-500"
        viewBox="0 -960 960 960"
      >
        <path d="m560-240-56-58 142-142H160v-80h486L504-662l56-58 240 240-240 240Z" />
      </svg>
      <div class="h-5 w-1 -ml-0.5 bg-primary group-hover:-translate-x-6 duration-500"></div>
    </NuxtLink>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";

const props = defineProps({
  vehicles: {
    type: Array,
    default: () => [],
  },
  loadMore: {
    type: Boolean,
    default: false,
  },
  topDealsPage: {
    type: Boolean,
    default: false,
  },
  linkMore: {
    type: Boolean,
    default: false,
  },
  moreText: {
    type: String,
    default: "Weitere Top Deals",
  },
  linkMoreRoute: {
    type: String,
    default: "/top-deals#topDeals",
  },
  hideCondition: {
    type: Boolean,
    default: false,
  },
  green: {
    type: Boolean,
    default: false,
  },
  brandSlugs: {
    type: Array,
    default: () => [],
  },
});

const loading = ref(false);
const lazyLoading = ref("lazy");
const displayedVehicles = ref([...props.vehicles]);
const loadMoreButton = ref(props.loadMore);

// Keep displayedVehicles synced if parent updates 'vehicles'
watch(
  () => props.vehicles,
  (newVehicles) => {
    displayedVehicles.value = [...newVehicles];
  }
);

async function loadMoreItems() {
  if (loading.value) return;
  loading.value = true;
  lazyLoading.value = "eager";

  // Decide which endpoint to call:
  // - If props.green -> /api/frontpage/site/electro (index/init style, 3 at a time)
  // - Otherwise -> /api/frontpage/component/top-deals (offset/limit = 6)
  if (props.green) {
    // "Electro" route
    const response = await $fetch("/api/frontpage/site/electro", {
      method: "POST",
      body: {
        index: displayedVehicles.value.length, // how many we have so far
        init: 3, // how many we want each time
        green: true,
        brandSlugs: props.brandSlugs.length ? props.brandSlugs : [],
      },
    });

    // The electro route returns { topDeals: { total, items }, ... }
    const total = response.topDeals.total;
    const newItems = response.topDeals.items;

    if (newItems.length > 0) {
      displayedVehicles.value.push(...newItems);
    }

    if (displayedVehicles.value.length >= total) {
      loadMoreButton.value = false;
    }
  } else {
    // Normal "top-deals" route
    const response = await $fetch("/api/frontpage/component/top-deals", {
      method: "POST",
      body: {
        offset: displayedVehicles.value.length,
        limit: 6,
        green: props.green,
        brandSlugs: props.brandSlugs.length ? props.brandSlugs : [],
      },
    });

    // Normal route returns { total, items }
    if (response.items.length > 0) {
      displayedVehicles.value.push(...response.items);
    }

    if (displayedVehicles.value.length >= response.total) {
      loadMoreButton.value = false;
    }
  }

  loading.value = false;
}
</script>
