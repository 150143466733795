<template>
  <div
    v-bind="$attrs"
    class="bg-neutral-100 rounded-br-3xl cursor-pointer transition-transform duration-300 hover:scale-105 h-fit"
    @click="openModal"
  >
    <!--        {{ item.variants.map((v) => v.fuelId) }}-->
    <NuxtImg
      width="480"
      height="270"
      :loading="loading"
      :alt="`Fahrzeugmodel ${item.modelName} ${item.name} mit ${item.fuel}`"
      class="w-full aspect-video object-cover"
      :src="`/uploads/vehicle/${item.id}/${item.id}.webp`"
    />

    <div class="px-8 py-5 flex flex-col justify-between h-full">
      <div>
        <div class="text-primary font-medium text-sm mb-1">{{ item.brandName }}</div>
        <FrontpageHeadline level="5" class="mb-2"
          >{{ item.modelName }} {{ item.name }}</FrontpageHeadline
        >
        <div class="min-h-[24px]">{{ description }}</div>
        <!-- Enforce space for descriptions -->
        <div v-if="!hideCondition" class="text-xs text-gray-500 mt-1">{{ item.conditions }}</div>
      </div>

      <!-- Keep buttons at the bottom -->
      <div class="grid grid-cols-1 lg:grid-cols-2 gap-2 w-full items-end">
        <div class="flex flex-wrap items-center gap-2 w-full min-w-0">
          <span v-if="!account" class="text-gray-600 text-xs break-words w-full">
            Konditionen nur für Mitglieder sichtbar
          </span>
          <span v-else class="text-primary text-2xl font-bold w-auto break-words">
            {{ highestDiscount }}{{ highestEuroDiscount ? "€" : "%" }}
          </span>
        </div>
        <div class="grid gap-2 w-full">
          <FrontpageButton size="small" class="w-full lg:w-auto" @click.stop="openModal(true)">
            Mehr erfahren
          </FrontpageButton>
        </div>
      </div>
    </div>
  </div>

  <Modal v-model="showModal" class="!px-0 sm:px-8" @click.self="closeModal">
    <Card class="m-auto w-full max-w-screen-2xl rounded-lg overflow-hidden shadow-lg">
      <div class="flex justify-between items-center pb-4 px-1 border-b">
        <h3 class="text-xl sm:text-2xl font-semibold text-primary">
          {{ item.brandName }}
        </h3>
      </div>

      <div class="relative">
        <NuxtImg
          class="object-cover xl:max-h-[25rem] rounded-md mx-auto"
          :src="`/uploads/vehicle/${item.id}/${item.id}.webp`"
          :alt="`${item.modelName} Banner`"
        />
        <div
          v-if="highestDiscount && account"
          class="absolute bottom-0 right-0 bg-primary/50 sm:bg-primary/80 text-white font-bold px-2 sm:px-4 py-0.5 rounded-br-2xl text-lg"
        >
          {{ highestDiscount }}{{ highestEuroDiscount ? "€" : "%" }}
        </div>

        <div
          v-if="fuelTypes.length > 1"
          class="absolute top-0 md:right-0 bg-primary/50 sm:bg-primary/80 text-white font-bold px-3 py-2 rounded-br-2xl text-lg"
        >
          <span class="text-xs md:text-sm text-white mb-1 block">Auch erhältlich als</span>

          <div class="block sm:hidden text-xs text-gray-100">
            {{ fuelTypes.join(", ") }}
          </div>

          <div class="hidden sm:flex sm:flex-col gap-1 md:gap-0.5">
            <span
              v-for="fuel in fuelTypes"
              :key="fuel"
              class="md:bg-primary/10 text-gray-100 text-xs rounded-br-xl"
            >
              {{ fuel }}
            </span>
          </div>
        </div>
      </div>

      <div class="sm:p-4 overflow-y-auto max-h-[calc(60vh-18rem)]">
        <div class="mb-4">
          <div class="text-primary font-semibold text-lg">{{ item.brandName }}</div>
          <h4 class="text-xl font-bold mb-2">{{ item.modelName }} {{ item.name }}</h4>
          <p v-if="!hideCondition" class="text-sm text-gray-600 mb-4">{{ item.conditions }}</p>
        </div>

        <div class="hidden lg:block overflow-x-auto rounded-lg border border-gray-200">
          <table class="w-full text-sm text-left">
            <thead class="text-xs uppercase bg-gray-50 text-gray-600">
              <tr>
                <th class="py-3 px-4">Modell</th>
                <th class="py-3 px-4">Fahrzeugart</th>
                <th class="py-3 px-4">Getriebe</th>
                <th class="py-3 px-4">Kraftstoff</th>
                <th class="py-3 px-4">Reichweite</th>
                <th class="py-3 px-4">Ladedauer</th>
                <th class="py-3 px-4">Rabatt</th>
                <th class="py-3 px-4">Info</th>
                <th class="py-3 px-4 text-right">Aktion</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="variant in item.variants"
                :key="variant.id"
                class="bg-white border-t hover:bg-gray-50"
              >
                <td class="py-3 px-4 whitespace-nowrap">{{ item.modelName }}</td>
                <td class="py-3 px-4">
                  {{ types.find((t) => t.value === variant.typeId)?.name || "-" }}
                </td>
                <td class="py-3 px-4">
                  {{ transmissions.find((tr) => tr.value === variant.transmissionId)?.name || "-" }}
                </td>
                <td class="py-3 px-4">{{ variant.fuel || "-" }}</td>
                <td class="py-3 px-4">
                  {{ variant.range ? `${variant.range} km` : "-" }}
                </td>
                <td class="py-3 px-4">
                  {{ variant.chargingSpeed ? `${variant.chargingSpeed} min` : "-" }}
                </td>
                <td class="py-3 px-4">
                  <span v-if="account" class="text-lg font-semibold text-primary">
                    {{ variant.discount }}{{ variant.euroDiscount ? "€" : "%" }}
                  </span>
                  <span v-else class="text-gray-500">Nur für Mitglieder</span>
                </td>
                <td class="py-3 px-4 max-w-xs break-words whitespace-normal">
                  <span class="block line-clamp-4">
                    {{ variant.descriptionShort || "-" }}
                  </span>
                </td>
                <td class="py-3 px-4 text-right">
                  <FrontpageButton
                    class="font-medium text-sm transition-colors"
                    size="small"
                    @click.stop="account ? navigateTo(abrufscheinLink) : handleLoginClick()"
                  >
                    {{ account ? "Zum Abrufschein" : "Login" }}
                  </FrontpageButton>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="lg:hidden space-y-4">
          <div
            v-for="variant in item.variants"
            :key="variant.id"
            class="bg-white border border-gray-200 rounded-lg p-4 shadow-sm"
          >
            <div class="flex justify-between items-center mb-3">
              <span class="font-medium">{{ item.modelName }}</span>
              <span v-if="account" class="text-lg font-semibold text-primary">
                {{ variant.discount }}{{ variant.euroDiscount ? "€" : "%" }}
              </span>
              <span v-else class="text-primary italic text-xs">Rabatt nur für Mitglieder*</span>
            </div>

            <div class="grid grid-cols-2 gap-2 text-sm mb-4">
              <div>
                <div class="text-gray-500">Fahrzeugart</div>
                <div>{{ types.find((t) => t.value === variant.typeId)?.name || "-" }}</div>
              </div>
              <div>
                <div class="text-gray-500">Getriebe</div>
                <div>
                  {{ transmissions.find((tr) => tr.value === variant.transmissionId)?.name || "-" }}
                </div>
              </div>
              <div>
                <div class="text-gray-500">Kraftstoff</div>
                <div>{{ variant.fuel || "-" }}</div>
              </div>
              <div>
                <div class="text-gray-500">Reichweite</div>
                <div>{{ variant.range ? `${variant.range} km` : "-" }}</div>
              </div>
              <div>
                <div class="text-gray-500">Ladedauer</div>
                <div>{{ variant.chargingSpeed ? `${variant.chargingSpeed} min` : "-" }}</div>
              </div>
              <div>
                <div class="text-gray-500">Info</div>
                <div class="max-w-full break-words whitespace-normal text-sm text-gray-700">
                  <span class="block line-clamp-4">
                    {{ variant.descriptionShort || "-" }}
                  </span>
                </div>
              </div>
            </div>

            <FrontpageButton
              class="w-full font-medium py-2 px-4 rounded-br-xl text-sm"
              size="small"
              @click.stop="account ? navigateTo(abrufscheinLink) : handleLoginClick()"
            >
              {{ account ? "Zum Abrufschein" : "Login" }}
            </FrontpageButton>
          </div>
        </div>
      </div>
    </Card>
  </Modal>
</template>
<script setup>
import { transmissions, types } from "~/server/variables/vehicle.js";

const showModal = ref(false);
const props = defineProps({
  item: {
    type: Object,
    default: () => ({}),
    required: true,
  },
  loading: {
    type: String,
    default: "lazy",
  },
  hideCondition: {
    type: Boolean,
    default: false,
  },
});

const { account, layout } = useStore();
const route = useRoute();
const source = ref(null);

watch(
  () => route.path,
  (newPath) => {
    if (newPath === "/firmenwagen-finder") {
      source.value = 2;
    } else {
      source.value = null;
    }
  },
  { immediate: true }
);

const abrufscheinLink = computed(() => {
  const baseUrl = `/abrufschein?model_id=${props.item.modelId}`;
  return source.value ? `${baseUrl}&source=${source.value}` : baseUrl;
});

const description = computed(() =>
  [
    props.item.commercial ? "Nutzfahrzeug" : props.item.type || undefined,
    props.item.transmission || undefined,
    props.item.fuel || undefined,
  ]
    .filter(Boolean)
    .join(", ")
);

const fuelTypes = computed(() => {
  const fuels = props.item.variants
    ?.map((v) => v.fuel)
    .filter(Boolean)
    .map((f) => f.trim());

  return [...new Set(fuels)];
});

const hasMultipleVariants = computed(() => props.item.variants?.length > 1);

const highestDiscount = computed(() => {
  if (!props.item?.variants?.length) return props.item.discount;
  return Math.max(...props.item.variants.map((v) => parseFloat(v.discount) || 0));
});

const highestEuroDiscount = computed(() => {
  if (!props.item?.variants?.length) return props.item.euroDiscount;
  return props.item?.variants.some((v) => v.euroDiscount);
});

const openModal = (force = false) => {
  if (hasMultipleVariants.value || force) {
    showModal.value = true;
  }
};

const closeModal = () => {
  showModal.value = false;
};

const handleLoginClick = () => {
  closeModal();
  layout.value.login = true;
};
</script>
